.voteRow {
  display: flex;
  border-top: solid 1px var(--border-color);
  padding: 1rem;

  &:first-child {
    border-top: none;
  }

  :last-child {
    text-align: right;
  }
}

.voteCol {
  flex: 1;
}
