.error {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--link-color);
}

.connectorsButtons {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
