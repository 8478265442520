.proposals {
  .proposals-header {
    width: 100%;
  }
}

.proposal-row {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit !important; /* no underline */
  }

  .proposal-header {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);

    .proposal-state {
      padding: 0.5rem;
      border: 1px solid var(--border-color);
      border-radius: 2rem;
    }
  }

  .proposal-body {
    display: flex;
    flex-direction: column;

    h2 {
      margin-top: 0;
    }

    .body-description {
      color: var(--text-color);
      font-size: 1.25rem;
      margin: 0;
    }
  }

  &:hover {
    border-color: var(--link-color);

    .proposal-header {
      color: var(--link-color);
    }

    .proposal-body {
      .body-description {
        color: var(--link-color);
      }
    }
  }
}
