[data-color-theme="light"] {
  --primary-color: #384aff;
  --bg-color: white;
  --text-color: #57606a;
  --link-color: #111111;
  --heading-color: #111111;
  --border-color: #d0d7de;
  --header-bg: white;
  --block-bg: transparent;
  --disable-color: gray;
}
[data-color-theme="dark"] {
  --primary-color: #384aff;
  --bg-color: #282c34;
  --text-color: #a0a9b4;
  --link-color: white;
  --heading-color: white;
  --border-color: #353d45;
  --header-bg: #151617;
  --block-bg: transparent;
  --disable-color: gray;
}

body {
  margin: 0;
}

.m-1 {
  margin: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.p-1 {
  padding: 1rem;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.w-2\/4 {
  width: 50%;
}

.textColor {
  color: var(--text-color);
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.alingItemsCenter {
  align-items: center;
}

.boldText {
  font-weight: 600;
}

.flex {
  display: flex;
}

.flexAuto {
  flex: 1 1 auto;
}

.border {
  border: solid 1px var(--border-color);
}

.border-t {
  border-top: solid 1px var(--border-color);
}

.rounded-md {
  border-radius: 0.5rem;
}

a.link {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit !important; /* no underline */
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  &:hover {
    color: var(--link-color);
  }
}

.secondaryButton {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: solid 1px var(--border-color);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 600;
  width: 100%;
  cursor: pointer;

  &.active {
    border-color: var(--link-color);
    color: var(--link-color);
  }

  &:hover {
    border-color: var(--link-color);
    color: var(--link-color);
  }
}

.primaryButton {
  display: flex;
  justify-content: center;
  background-color: var(--border-color);
  border: 0px;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: var(--link-color);
  font-weight: 600;
  width: 100%;
  cursor: pointer;

  &.active {
    color: white;
    background-color: var(--primary-color);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.deleteButton {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  color: var(--border-color);
  font-weight: 600;
  cursor: pointer;

  &:hover,
  &:active {
    color: #e2435c;
  }

  &:active {
    opacity: 0.5;
  }
}
