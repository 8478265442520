.proposal {
  .proposalTitle {
    font-size: 36px;
  }

  .proposalHeader {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    margin-bottom: 1rem;

    .proposalState {
      padding: 0.5rem;
      border: 1px solid var(--border-color);
      border-radius: 2rem;
    }
  }
}
