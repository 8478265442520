.actionDropdown {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: solid 1px var(--border-color);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: var(--link-color);
  font-weight: 600;
  width: 100%;
  cursor: pointer;

  &:hover {
    border-color: var(--link-color);
  }

  option {
    color: black;
  }
}
