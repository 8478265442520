.App {
  background: var(--bg-color);
  color: var(--text-color);
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  max-width: 880px;
  margin: auto;
  padding: 0 10px;

  @media (max-width: 600px) {
    justify-content: initial;
    max-width: 100%;
  }
}

.app-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  width: 80%;

  @media (max-width: 600px) {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    margin: 1rem 0;
    font-weight: 600;
    line-height: 1.4 !important;
  }
  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit !important; /* no underline */
  }
}

.app-widget {
  border: solid 1px var(--border-color);
  border-radius: 1rem;
  width: 80%;
  margin: auto;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.app-widget-header {
  color: var(--link-color);
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: solid 1px var(--border-color);
}

.app-widget-item {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
button,
input {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
